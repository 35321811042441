@import "../../../scss/variables";

$primary: #EC712E;

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import "@coreui/coreui/scss/coreui";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

@import "../../../scss/layout";
@import "../../../scss/example";


body {
	height:100vh;
	margin:0 auto;
	position:relative;
	//overflow:hidden;
	z-index:1;
	background:url('../../../assets/images/fond_2.jpg') no-repeat center center;
	background-size:cover;	
}

input.oldTheme {
	//border-right: transparent !important;
	border-radius:0.25rem !important;
	padding-right: 42.5px;
	&:focus {
		box-shadow: none !important;
		border-color: $primary;
	}
}
.icnOldTheme {
	z-index:10;
	border: none !important;
	position:absolute;
	top: 0;
	right:0;
	background-color: initial !important;
	padding: 0.75rem 0.75rem;
}
.btn-primary {
	color: $white;
	&:hover {	color: $white; }
	&:active { color: #00000055 !important;	}
	&:focus-visible {	color: $white; }
}
.form-check-label {
	font-size:small ;
}
.form-check-label, .form-check-input {
	cursor: inherit;
}
.frmCheck {
	cursor: pointer;
}
.form-check-input {
	&:focus {
		box-shadow: none;
		border: 1px solid rgba(0, 0, 21, 0.25);
	}
	&:hover{
		border-color: $primary;
		box-shadow: none;
	}
	&:checked {
		border-color: $primary;
		background-color: $primary;
	}
}
.txtInfo {
	font-size: smaller;
	& .link {
		color: $primary;
		cursor: pointer;
		&:hover {
			color: $primary;
			text-decoration: none;
		}
	}
}

