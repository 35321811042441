// Here you can add other styles
$borderRadius: 5px;
$theme-secondary-color: #77BBFF;
$oldTheme-primary: #EC712E;

.bg-light {
	background-color: $white !important;
}
.simplebar-mask {
	//margin: $borderRadius !important;
}
.sidebar-nav .nav-link {
	border-radius: $borderRadius;
}
.sidebar-nav .nav-group.show {
	border-radius: $borderRadius;
}
.sidebar-nav .nav-group {
	border-radius: $borderRadius;
}
.nav-group.show > .nav-group-toggle {
	background-color:  $white !important;
}
.nav-group-items .nav-link {
	color: $white !important;
	&:hover {
		color: $black !important;
		background-color: $theme-secondary-color;
		margin-left: 5px;
		margin-right: 5px;
		& img {
			filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(240deg) brightness(93%) contrast(105%);
		}
	}
}
.nav-group-items .nav-icon {
	color: $white !important;
	filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(260deg) brightness(103%) contrast(102%);
}
.nav-group-items .nav-link.active {
	color: $black !important;
	background-color: $theme-secondary-color;
	margin-left: 5px;
	margin-right: 5px;
	& img {
		filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(240deg) brightness(93%) contrast(105%);
	}
}
.sidebar-nav {
	padding: $borderRadius +5;
}
.nav-item, .nav-group {
	margin-top: 5px;
	margin-bottom: 5px;
}
.sidebar-nav .nav-group .nav-group-items {
	padding-bottom: 5px;
}

.card {
	filter: drop-shadow(1px 2px 3px #00000055 );
	border-width: 0px !important;
}
.myHeader {
	padding: 0;
	font-size: small;
	min-height: initial;
	background-color: $gray-100;
}

body {
	background: url('../assets/images/shape-orange-1.png') $white right 0 top 0 no-repeat;
	background-attachment: fixed;
	background-size: 40%;
}
.col-blank {
	color: $white;
}
.btn-primary {
	color: $white;
	&:hover {	color: $white; }
	&:active { color: #00000055 !important;	}
	&:focus-visible {	color: $white; }
}
.btn-outline-primary {
//	color: $black;
	&:hover {	color: $white; }
	&:active { color: #00000055 !important;	}
//	&:focus-visible {	color: $white; }
}
.login-bg {
	height:100vh;
	margin:0 auto;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background: url('../assets//images/fond_2.jpg') no-repeat center center;
	background-size: cover;
}
.btnOldTheme {
	color: $white;
	&:hover {	border-color: #ef864d !important; background-color: #ef864d !important; color: $white !important; }
	&:active { border-color: #f08d58 !important; background-color: #f08d58 !important; color: #00000055 !important;	}
	&:focus-visible {	color: $white !important; }
	border-color: $oldTheme-primary;
	background-color: $oldTheme-primary;
}
.frmCheck {
	cursor: pointer;
	& >.form-check-input:focus {
		box-shadow: none;
		border: 1px solid rgba(0, 0, 21, 0.25);
	}
	& >.form-check-input:hover{
		border-color: $oldTheme-primary;
		box-shadow: none;
	}
	& >.form-check-input:checked {
		border-color: $oldTheme-primary;
		background-color: $oldTheme-primary;
	}
}
// PARTI MON COMPTE
.btn-mini {
	font-size: smaller;
	margin-left:5px;
	&:active {
		img {
			filter: invert(0%) sepia(0%) saturate(1%) hue-rotate(260deg) brightness(103%) contrast(102%) opacity(0.3333333);
		}
	}
}
.no-padding-hautbas {
	padding-top: 3px !important;
	padding-bottom: 3px !important;
}
.icon-btn-header {
	width: 16px;
	margin-right:10px;
	filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(260deg) brightness(103%) contrast(102%);
}
.img1{
	background-image: url('../assets/images/bleu1.png');
}
.img2{
	background-image: url('../assets/images/bleu2.png');
}
.img3{
	background-image: url('../assets/images/orange1.png');
}
.bodyStats {
	background-repeat: no-repeat ;
	border-radius: 10px;
	background-size:30% 100%;
	.ligne2 {
		font-size: small;
	}
	& .spanImg {
		position: absolute;
	}
	& .un {
		top : 10px;
		left: 10px;
	}
	& .un-1 {
		top : 5px;
		left: 5px;
	}
	& .deux {
		top : 45px;
		left:	10px;
	}
	& .deux-1 {
		top : 45px;
		left:	9px;
	}
	& .trois {
		top : 60px;
		left:	4px;
	}
	& img {
		filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(260deg) brightness(103%) contrast(102%);
	}
}
.titreBoldStats {
	font-size:xx-large;
	font-weight: 400;
}
.icnReouvre {
	width:1.9rem;
}
.icnConfie {
	width: 2.3rem;
}
.icnDernierReversement {
	width: 3rem;
}
.icnCake {
	width: 1.9rem;
}
.ligneHead {
	background-color: #ebedef;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	padding-top: 15px;
	padding-bottom: 15px;
}
.head1 {
	font-size: small;
	font-weight: bold;
}
.montbl {
	padding-left: 1.5rem;
	width:90% !important;
}
.ligneNorm {
	padding-top: 15px;
	padding-bottom: 15px;
	font-size: small;
	border-left:1px solid #ebedef;
	border-right:1px solid #ebedef;
}
.odd {
	background-color: #ebedef;
}
.ligneFin {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom: 1px solid #ebedef;
	-webkit-box-shadow: 0px 10px 13px -7px rgba(0,0,0,0.05), 0px 6px 15px 0px rgba(0,0,0,0);
	box-shadow: 0px 10px 13px -7px rgba(0,0,0,0.05), 0px 6px 15px 0px rgba(0,0,0,0);
}
.dashboardGraph {
	width: 100% !important;
	height: auto !important;
}
.btnDashboard {
	font-size:x-small ;
	display: flex;
	align-items: center;
	border-radius: 10px;
	& img {
		filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(260deg) brightness(103%) contrast(102%);
		width: 30px;
    margin-right: 10px;
	}
}
.btnHeader {
	font-size:x-small ;
	display: flex;
	align-items: center;
	border-radius: 10px;
	padding-left:10px;
	padding-right:10px;
	cursor: pointer;
	& img {
		//filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(260deg) brightness(103%) contrast(102%);
		filter: invert(47%) sepia(24%) saturate(301%) hue-rotate(180deg) brightness(93%) contrast(94%);
		width: 30px;
    margin-right: 10px;
	}
}
.nav-item, .nav-group {
	margin-bottom: 0px;
}
.dataTables_wrapper{
	padding-top:10px;
}
.tableTabSet {
	padding-top:10px;
}
.correctPosi {
	position: relative;
	top: -8px;
}
.icnMontantConfier{
	width:30px;
}
.btn-default {
	font-size: small;
	background-color: #C3ECFF;
	color: #000;
	border-color: #77BBFF;
	&:hover {
		background-color: #77BBFF !important;
		border-color: #007CF7;
		color: #fff;
	}
	&.active{
		background-color: #007CF7;
		border-color: #007CF7;
		color: #fff;
	}
}
th {
	border-top: 1px solid rgba(0,0,0,0.3) !important;
}
.fond-grid {
	position: relative;
	overflow: hidden;
	padding-left: 1.5rem;
	padding-right: 0.6rem;
	border-radius: 15px;
}
.imgFondGrid {
	position: absolute;
	bottom: -36px;
	height: 202px;
	right: -88px;
}

// FILE UPLOAD
.dropzone {
  text-align: center;
  padding: 30px;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  //cursor: pointer;
  margin-bottom: 20px;
}

.selected-file-wrapper {
  text-align: center;
}

.selected-file {
  color: #000;
  font-weight: bold;
}
.boxDropZone {
	padding: 30px;
	background-color: #fafafa;
	background-image: -webkit-linear-gradient(top, #fafafa, #f2f1f1);
	background-image: -moz-linear-gradient(top, #fafafa, #f2f1f1);
  background-image: linear-gradient(180deg, #fafafa, #f2f1f1);

	border-radius: 3px;
	box-shadow: 0 0 10px rgb(0,0,0,0.16);
}


// DONUTS progressbar
.loader{
	position: relative;
	width: 30px;
	height: 30px;
	float:left;
	margin-right: 5px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.loader-bg
{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 5px solid rgba(0, 0, 0, 0.2);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.spiner-holder-1
{
	position: absolute;
	top:0;
	left:0;
	overflow: hidden;
	width: 50%;
	height: 50%;
	background: transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.spiner-holder-2
{
	position: absolute;
	top:0;
	left:0;
	overflow: hidden;
	width: 100%;
	height: 100%;
	background: transparent;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.loader-spiner
{
	width: 200%;
	height: 200%;
	border-radius: 50%;
	border: 5px solid #007CF7; // rgba(255, 255, 255, 0.3);
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	-o-box-sizing: border-box;
	box-sizing: border-box;
}
.animate-0-25-a
{
	-webkit-transform:rotate(90deg);
	-moz-transform:rotate(90deg);
	-o-transform:rotate(90deg);
	transform: rotate(90deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.animate-0-25-b
{
	-webkit-transform:rotate(-90deg);
	-moz-transform:rotate(-90deg);
	-o-transform:rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.animate-25-50-a
{
	-webkit-transform:rotate(180deg);
	-moz-transform:rotate(180deg);
	-o-transform:rotate(180deg);
	transform: rotate(180deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.animate-25-50-b
{
	-webkit-transform:rotate(-90deg);
	-moz-transform:rotate(-90deg);
	-o-transform:rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.animate-50-75-a
{
	-webkit-transform:rotate(270deg);
	-moz-transform:rotate(270deg);
	-o-transform:rotate(270deg);
	transform: rotate(270deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.animate-50-75-b
{
	-webkit-transform:rotate(-90deg);
	-moz-transform:rotate(-90deg);
	-o-transform:rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin:100% 100%;
}
.animate-75-100-a
{
	-webkit-transform:rotate(0deg);
	-moz-transform:rotate(0deg);
	-o-transform:rotate(0deg);
	transform: rotate(0deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.animate-75-100-b
{
	-webkit-transform:rotate(-90deg);
	-moz-transform:rotate(-90deg);
	-o-transform:rotate(-90deg);
	transform: rotate(-90deg);
	-webkit-transform-origin:100% 100%;
	-moz-transform-origin:100% 100%;
	-o-transform-origin:100% 100%;
	transform-origin: 100% 100%;
}
.text
{
	text-align: center;
	padding-top:26%;
	font-size: 6px;
	color: rgba(0, 0, 0, 0.5);
	font-family: Verdana;
}
.dnl-btn{
	margin-left: auto;
	cursor: pointer;
}
.fileSize {
	display:block;
	font-size:12px;
}
a {
	text-decoration: none;
}
.modal-content {
	font-size: smaller;
}
#bodyDlg {
	& .label {
		font-weight:bold;
	}
	& pre {
    display: block;
    padding: 9.5px;
    margin: 0 0 10px;
    font-size: 13px;
    line-height: 1.42857143;
    color: #333;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;
	}
	& .card-header {
		background-color: #FFE6D3 !important;
		border-color: #FFE6D3 !important;
	}
	& hr {
		border-color: #FFE6D3 !important;
		opacity: 1;
	}
}
#prix_debiteur {
	text-align: right;
}


.tooltip1 {
	display:inline-block;
	position:relative;
	border-bottom:1px dotted #666;
	text-align:left;
}

.tooltip1 .top {
	min-width:150px;
	top:-20px;
	left:50%;
	transform:translate(-50%, -100%);
	padding:10px 20px;
	color:#EEEEEE;
	background-color:#000000;
	font-weight:normal;
	font-size:13px;
	border-radius:8px;
	position:absolute;
	z-index:99999999;
	box-sizing:border-box;
	border:1px solid #333333;box-shadow:0 1px 8px rgba(0,0,0,0.5);
	visibility:hidden; opacity:0; transition:opacity 0.8s;
}

.tooltip1:hover .top {
	visibility:visible; opacity:1;
}

.tooltip1 .top i {
	position:absolute;
	top:100%;
	left:50%;
	margin-left:-12px;
	width:24px;
	height:12px;
	overflow:hidden;
}

.tooltip1 .top i::after {
	content:'';
	position:absolute;
	width:12px;
	height:12px;
	left:50%;
	transform:translate(-50%,-50%) rotate(45deg);
	background-color:#000000;
	border:1px solid #333333;box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

.tooltip2 .top {
	min-width:150px;
	top:-20px;
	left:50%;
	transform:translate(-50%, -100%);
	padding:10px 20px;
	color:#EEEEEE;
	background-color:#000000;
	font-weight:normal;
	font-size:13px;
	border-radius:8px;
	position:absolute;
	z-index:99999999;
	box-sizing:border-box;
	border:1px solid #333333;box-shadow:0 1px 8px rgba(0,0,0,0.5);
	visibility:hidden; opacity:0; transition:opacity 0.8s;
}

.tooltip2:hover .top {
	visibility:visible; opacity:1;
}

.tooltip2 .top i {
	position:absolute;
	top:100%;
	left:50%;
	margin-left:-12px;
	width:24px;
	height:12px;
	overflow:hidden;
}

.tooltip2 .top i::after {
	content:'';
	position:absolute;
	width:12px;
	height:12px;
	left:50%;
	transform:translate(-50%,-50%) rotate(45deg);
	background-color:#000000;
	border:1px solid #333333;box-shadow:0 1px 8px rgba(0,0,0,0.5);
}

.headGraph{
	font-weight: bold;
	margin-bottom: 0.5rem;
}

.reactSelect {
  width: 100% !important;
}



// Add Gab
.listFile {
	color: $primary;
	cursor: pointer;
	display: block;
	&:hover {
		color: black;
	}
}

.treeListFile {
	user-select: none;
}

.rct-node-icon {
	color: $primary !important;
}
.rct-bare-label {
	cursor: pointer !important;
	user-select: none;
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
}
.rct-node-leaf, .rct-text{
	&:hover {
		background-color: rgba(0, 0, 0, 0.04);
	}
}